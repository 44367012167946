@media only screen and (max-width: 1440px) {
    .container {
        width: 98%;
    }
}

/* 40em == 640px */
@media only screen and (max-width: 40em) {

    h1 {
        font-size: 4.69em;
    }

    h2 {
        font-size: 2.69em;
    }

    h3 {
        font-size: 1.21em;
    }

    h4 {
        font-size: 1.2em;
    }

    .socials img {
        height: 40px;
    }

    .socials a {
        margin: 0 7px;
    }

    .image-wrapper img {
        height: 100%;
    }

    .intro>div {
        padding-top: 70px;
        flex-direction: column-reverse;
        align-items: center;
        overflow: hidden;
    }

    .intro>div>div {
        width: 100%;
        padding: 0;
    }

    .intro .content-wrapper {
        width: 90%;
    }

    .intro .content-wrapper h3 {
        text-align: center;
        margin: 20px 0 20px 0;
        font-size: 1.21em;
    }

    .intro .image-wrapper {
        top: -20px;
    }

    .intro .image-wrapper img {
        width: 80%;
    }

    .intro .zlurpee {
        left: 10px;
        top: 20px;
        height: 70px;
        animation: shake 1.2s;
        animation-iteration-count: infinite;
    }

    .intro .zogz {
        right: 10px;
        top: 60px;
        height: 50px;
        animation: tilt 0.7s;
        animation-iteration-count: infinite;
    }

}


@media only screen and (max-width: 30em) {
    h1 {
        font-size: 3.69em;
    }

    h3 {
        font-size: 1.39em;
    }
}