@font-face {
    font-family: "Pixel";
    /*Can be any text*/
    src: local("Uni0553"),
        url("../fonts/pixel/Uni0553.woff") format("woff");
}

:root {
    --primary-color: #1D1C28;
    --secondary-color: #FF52C9;
    --white: #fff;
    --metis: #313042;
}