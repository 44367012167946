body {
  font-family: 'Pixel';
}

h1 {
  font-size: 6.69em;
}

h2 {
  font-size: 3.69em;
  text-align: center;
}

h3 {
  font-size: 2.69em;
}

h4 {
  font-size: 1.96em;
}

iframe {
  display: none;
}

.socials {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding-top: 60px;
}

.socials img {
  height: 52px;
  background-color: #fff;
  border-radius: 50%;
}

.socials a {
  margin: 0 17px;
  transition: transform .2s ease-in-out;
}

.socials a:hover {
  cursor: pointer;
  opacity: 0.8;
  transform: translateY(-3px);
}

.container {
  position: relative;
  width: 1440px;
  margin: 0 auto;
}



.image-wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.image-wrapper img {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center 15%;
}

.intro {
  position: relative;
  z-index: 1;

}

.intro>div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
}

.intro>div>div {
  width: 65%;
  padding: 50px;
  background: rgba(0, 0, 0, 0.4);
}

.intro .zlurpee {
  position: absolute;
  left: 120px;
  top: -60px;
  height: 220px;
  animation: shake 1.2s;
  animation-iteration-count: infinite;
}

.intro .zogz {
  position: absolute;
  right: 60px;
  top: 80px;
  height: 240px;
  animation: tilt 0.7s;
  animation-iteration-count: infinite;
}

.intro .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro .content-wrapper h3 {
  margin: 20px 0 40px 0;
  opacity: 0.9;
}

.intro .content-wrapper p {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 400;
  line-height: 1.4em;
}

footer {
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  padding: 25px;
  min-height: 90px;
}

footer p {
  width: 80%;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

footer strong {
  position: relative;
  padding: 0 4px;
  margin: 0 auto;
}

footer strong:before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--white);
}

@keyframes tilt {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  25% {
    transform: translate(5px, 5px) rotate(5deg);
  }

  50% {
    transform: translate(0, 0) rotate(0eg);
  }

  75% {
    transform: translate(-5px, 5px) rotate(-5deg);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}